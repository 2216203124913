import React, { useState, useEffect, useRef } from "react";

const Revealer = ({ children, className, log, force }) => {

  const el = useRef();
  const [show, setShow] = useState(force);

  const handleScroll = e => {
    const winH = window.innerHeight || window.offsetHeight || document.documentElement.clientHeight;
    const { top } = el.current.getBoundingClientRect()

    if (top < (winH * 0.9)) {
      setShow(true)

      if (log) {
        console.log('sho!')
      }
    }

    if (log) {
      console.log(winH, top)
    }
  }

  useEffect(() => {
    handleScroll()
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll, false)
    }
  }, [])

  useEffect(() => {
    if (show) {
      window.removeEventListener('scroll', handleScroll, false)
    }
  }, [show])

  return (
    <div className={className} ref={el} style={{
      opacity: show ? 1 : 0,
      // transform: `translateY(${show ? '0' : '2%'})`,
      transition: 'ease-out 1s',
      border: log ? 'solid 1px' : 0
    }}>
      {children}
    </div>
  )
}

export default Revealer
